import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { renderers } from "../utils/markdown"

const About = () => (
  <Layout>
    <SEO
      title="About"
      description="Developed by Theophilus Omoregbee for encouraging and tracking interesting things. Built with Gatsby and Strapi"
    />
    <StaticQuery
      query={graphql`
        query {
          strapiPage(static_id: { eq: "about" }) {
            content
          }
        }
      `}
      render={data => (
        <div className="uk-section">
          <div className="uk-container uk-container-small">
            <h1>About</h1>
            <ReactMarkdown
              source={data.strapiPage.content}
              renderers={renderers}
              escapeHtml={false}
            />
          </div>
        </div>
      )}
    />
  </Layout>
)

export default About
